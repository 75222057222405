.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* For Header */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Creates three equal columns */
  gap: 2.5px;
  /* Adjusts spacing between items */
  background-color: rgb(88, 172, 250);
}

.grid-container-for-title {
  display: grid;
  gap: 2.5px;
  /* Adjusts spacing between items */
  background-color: rgb(88, 172, 250);
}

.grid-item {
  padding: 2.5px;
  text-align: center;
  color: white;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}